@import url('https://fonts.googleapis.com/css?family=Nunito:300,400&display=swap');
@import "https://fonts.googleapis.com/css?family=Oswald:300,400,700";


html {
	overflow: -moz-scrollbars-none !important;
	max-width: 100%;
	overflow-x: hidden;

}

body {
  font-family: 'Work Sans', sans-serif;
  font-family: 'Merriweather', serif;
  font-family: 'Noto Serif', serif;
  font-family: 'Noto Serif Telugu', serif;
  font-family: 'Noto Serif JP', serif;
  font-family: Avenir-light;

  background-color:  white;
	font-size: 16px;
	scrollbar-width: none !important;
  margin:0px;
  overflow-x: hidden;
	-ms-overflow-style: none;
}
body p{
  line-height: 33px;
}

/*----------------------------NAV BAR-----------------------------*/


.navbar-left{
	float: left;
}

.navbar-right{
	float: right;
	padding: 1.3rem 1rem 0rem 0rem;
}

.fa-bars {
  color: black;
}

.menu-icon {
  display: none;
}

@media screen and (min-width: 960px) {

  .nav-links {
    color: black;
    display: grid;
    text-decoration: none;
    padding: 0rem;
    font-size: 16px;
    cursor: pointer;
  }

  .nav-links:hover {
    border-bottom: none;
    color: #f6e176;
    transform: scale(1.1);
    transition: all 0.2s ease-out;
  }


  .nav-menu {
    display: inline-block;
    grid-gap: 10px;
    list-style: none;
    margin-right: 2rem;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .nav-menu li{
    display: inline-block;
    font-size: 17px;
    padding: 16px;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    display: flex;
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 960px) {

  .nav-menu {
    padding-left: 0;
    display: flex;
    list-style: none;
    flex-direction: column;
    width: 0%;
    height: auto;
    position: absolute;
    top: 70px;
    opacity: 1;
    right:0%;
    left:100%;
    transition: all 0.5s ease;
    }

  .nav-menu.active {
    background: transparent;
    opacity: 1;
    transition: all 0.5s ease;
    list-style: none;
    height: auto;
    overflow: hidden;
    width: 100%;
    left:0%;
  }

  .nav-links {
    display:flex;
    background-color: white;
    font-size: 1.2rem;
    font-weight: lighter;
    color: black;
    text-decoration: none;
    padding: 1.5rem;
    justify-content: center;
  }

  .nav-links:hover {
    color: #f6e176;
    transform: scaleY(1.1);
    transition: 0.3s;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 50%);
    font-size: 1.8rem;
    padding: 0.5rem 1.5rem 0rem 0rem;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }
}



@media screen and (max-width: 500px) {

	    .nav-links {
	      font-size: 16px;
	    }
}


/*----------------------------NAV BAR-----------------------------*/

.navbar {
  margin: 0;
  width: 100%;
  background-color: white;
	color: black;
  position: fixed;
  z-index: 500;
	top: 0;
	transition: top 0.3s;
	display: inline-block;
	list-style: none;
	padding-right: 1em;
	box-shadow: 0px 0px 7px 4px rgba(0, 0, 0, 0.5);
	height: 6rem;
  position: fixed;
  display: block;
}
/* Sticky Class Designed to provide Sticky Property to the Navbar using JavaScript */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.navbar-left{
	float: left;
}

.navbar-right{
	float: right;
	padding: 1.3rem 1rem 0rem 0rem;
}

.fa-bars {
  color: black;
}

.menu-icon {
  display: none;
}

.dropbtn {
  background-color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  color: black;
  text-decoration: none;
  padding: 0rem 0.7rem 0.7rem 0.7rem;
  text-align: center;
  font-size: 16px;
}
.dropbtn:hover {
  border-bottom: none;
  color: #f6e176;
  transform: scale(1.1);
  transition: all 0.2s ease-out;
}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  background-color: white;
  position:absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding-top: 0.7rem;
}

.dropdown-content .a {
  color: black;
  padding: 13px 14px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}


@media screen and (min-width: 960px) {

  .nav-links {
    color: black;
    display: grid;
    text-decoration: none;
    padding:0rem 0.7rem 0.7rem 0.7rem;
    text-align: left;
    font-size: 16px;
  }

  .drop{
    font-size: 13px;

  }

  .nav-links:hover {
    border-bottom: none;
    color: #f6e176;
    transform: scale(1.1);
    transition: all 0.2s ease-out;
  }

  .nav-menu {
    display: inline-block;
    grid-gap: 10px;
    list-style: none;
    margin-right: 2rem;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .nav-menu li{
    display: inline-block;
    font-size: 17px;
    padding: 16px;
  }
}
@media screen and (max-width: 960px) {

  .nav-menu {
    padding-left: 0;
    display: flex;
    list-style: none;
    flex-direction: column;
    width: 0%;
    height: auto;
    position: absolute;
    top: 70px;
    opacity: 1;
    right:0%;
    left:100%;
    transition: all 0.5s ease;
    }
    

  .nav-menu.active {
    background: transparent;
    opacity: 1;
    transition: all 0.5s ease;
    list-style: none;
    height: auto;
    overflow: hidden;
    width: 100%;
    left:0%;
  }


  .nav-links {
    display:flex;
    background-color: white;
    font-size: 1rem;
    font-weight: lighter;
    color: black;
    text-decoration: none;
    padding: 1.3rem;
    justify-content: center;
    padding-left: 2.4rem;
  }

  .nav-links:hover {
    color: #f6e176;
    transform: scaleY(1.1);
    transition: 0.3s;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 50%);
    font-size: 1.8rem;
    padding: 0.5rem 1.5rem 0rem 0rem;
  }

  .fa-times {
    color: black;
    font-size: 2rem;
  }
  .dropdown {
    position: relative;
    display: contents;
  }
  #myDropdown{
    height: 100%;
  }
  .dropdown:hover .dropdown-content {
    display: contents;
    width:50%;
  }
  
  /* Style the dropdown button to fit inside the topnav */
.dropdown .dropbtn{
  border: none;
  outline: none;
  background-color: white;
  font-family: inherit;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  padding-left: 1.29rem;

  display:inline-flex;
  text-decoration: none;
  text-align: left;
}


.dropdown-content .nav-links{
  font-size: 14px;
  padding: 0.5rem 0rem 0rem 1.8rem;
}

}

@media screen and (max-width: 500px) {
  .dropdown {
    position: relative;
    display: contents;
  }

  .dropdown-content {
    display: none;
    background-color: white;
    position:fixed;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content .a {
    color: black;
    padding: 13px 14px;
    text-decoration: none;
    display: block;
  }
  /* Style the dropdown button to fit inside the topnav */
.dropdown .dropbtn {
  border: none;
  outline: none;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  display:inline-flex;
  text-decoration: none;
  text-align: left;
}
.dropdown-content .nav-links{
  font-size: 14px;
  padding: 0.3rem 0rem 0.3rem 1.8rem;
}

}

/*--------------------------Navbar PAGE--------------------------*/

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  display: block;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}






/*--------------------------LANDING PAGE--------------------------*/

#myVideo{
	min-height: 100%;
	min-width:100%;
	position:absolute;
	bottom:0;
	left:0;
 	pointer-events: none;
	z-index:0;

}
.overlay {
    top:0;
    left:0;
    z-index:1;


}
.logo{
  width: 20rem;
  height: 20rem;
  padding: 5rem 0 0 0rem;
}

.landing {
	height:45rem;
	width: 100%;
	background-image: url('./images/landing.jpg');
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	position: relative;
	background-repeat: no-repeat;
	justify-content: center;
	background-attachment: fixed;
	color: white;
}
.landing-info{
  display: flex;
  flex-direction: column;

}
.landing h2 {
	font-size: 65px;
  padding: 1rem 0rem 0rem 0rem;
  font-weight: 500;
	line-height: 80px;
	width: 100%;
  margin: 0;
  font-family: 'Merriweather', serif;
  font-family: 'Raleway', sans-serif;
  text-align: center;
}
.landing h3 {
	font-size: 40px;
  padding: 0rem 0rem 5rem 3rem;
  font-weight: 500;
	line-height: 70px;
	width: 70%;
  margin: 0;
  font-family: 'Noto Serif JP', serif;
}

.landing p{
  font-size: 20px;
  font-weight: 200;
  padding: 0rem 0rem 0rem 3rem;
  margin:0;
  width: 50%;
}


.button1 {
  font-family: Avenir-light;

	cursor:pointer;
	border:none;
	padding:0.8rem 2rem 0.8rem 2rem;
	font-size:15px;
  width: 15rem;
  background-color: #f6e176;
  color: black;
  border: 2px solid #f6e176;
  border-radius: 1px;

  position:absolute;
  right: 10rem;
  left: 10rem;
  margin-left: auto;
  margin-right: auto;
  top: 34rem;
}

.button1:hover {
  color: black;
  background-color: white;
  border: white;
	-webkit-transition:background 0.3s ease-in-out;
	-moz-transition:background 0.3s ease-in-out;
	transition:background-color 0.3s ease-in-out;
}


@media only screen and (max-width: 1000px) {


  .landing h2 {
    font-size: 47px;
    padding: 3.5rem 2rem 0rem 1rem;
    font-weight: 500;
    line-height: 80px;
    width: 100%;
    margin: 0;
    font-family: 'Merriweather', serif;
    font-family: 'Raleway', sans-serif;
    text-align: center;
  }

  .landing p{
    font-size: calc(10px + (50 - 35) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 400;
  	padding: 0rem 2rem 0rem 3rem;
  }

  .info button{
    padding-left: 2rem;
  }

  .button1 {
  	font-size:12px;
  }
}

@media only screen and (max-width: 768px) {
  .landing {
    min-height: 70vh;
    background-size: 100rem;
    width: auto;
    height: 40rem;
  }
  .landing h2 {
    font-size: 40px;
    padding: 3.5rem 2rem 0rem 1rem;
    font-weight: 500;
    line-height: 80px;
    width: 100%;
    margin: 0;
    font-family: 'Merriweather', serif;
    font-family: 'Raleway', sans-serif;
    text-align: center;
  }

  .landing p{
    font-size: calc(10px + (50 - 35) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 400;
  	padding: 0rem 2rem 0rem 3rem;
  }
  .button1 {
    cursor:pointer;
    border:none;

    position:absolute;
    right: 10rem;
    left: 10rem;
    margin-left: auto;
    margin-right: auto;
    top: 36rem;
  }
}

@media only screen and (max-width: 650px) {

  .landing h2 {
		font-size: 35px;
	  padding: 2rem 0rem 0rem 0rem;
	  font-weight: 500;
		line-height: 40px;
	}
	.landing h3 {
		font-size: 30px;
	  padding: 0rem 0rem 5rem 3rem;
	  font-weight: 500;
		line-height: 60px;
		width: 70%;
	  margin: 0;
	}

  .landing p{
    font-size: 14px;
  	font-weight: 200;
  	padding: 1rem 3rem 0rem 3rem;
    margin:0;
    line-height: 1.3rem;
  }
  .button1 {
    cursor:pointer;
    border:none;
    position:absolute;
    right: 6rem;
    left: 6rem;
    margin-left: 3rem;
    margin-right: 3rem;
    top: 36rem;
    width: auto;
  }
}

@media only screen and (max-width: 500px) {
  .landing {
    min-height: 90vh;
    background-size: contain;
    background-position: center center; /* Center the image */
    background-image: url('./images/landing2.jpg');
    background-attachment: local;
  }

	.landing h2 {
		font-size: 32px;
	  padding: 2rem 0rem 0rem 0rem;
	  font-weight: 500;
		line-height: 40px;
	}
	.landing h3 {
		font-size: 30px;
	  padding: 0rem 0rem 5rem 3rem;
	  font-weight: 500;
		line-height: 60px;
		width: 70%;
	  margin: 0;
	}

  .landing p{
    font-size: 14px;
  	font-weight: 200;
  	padding: 1rem 3rem 0rem 3rem;
    margin:0;
    line-height: 1.3rem;
  }
  .button1 {
    cursor:pointer;
    border:none;
    position:absolute;
    right: 3rem;
    left: 3rem;
    margin-left: 3rem;
    margin-right: 3rem;
    top: 34rem;
    width: auto;
  }
}


/*----------------------------------------------------------------*/

/*----------------------------ABOUT US----------------------------*/

.about-section {
  display: inline-flex;
	flex-direction: column;
  background-color: #f6f6f6;
  color: black;
  align-content: flex-start;
  text-align: left;
  margin:0;
  padding:0;
}

.about-section h4 {
	font-size: 38px;
	font-weight: 200;
	padding: 1rem 5rem 1rem 5rem;
  margin-bottom: 0;
}

.about-section p {
  font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 5rem 0rem 5rem;
  font-size: 20px;
  line-height: 33px;
}

.about-split{
  display:flex;
  resize: none;
}
.about-image{
  width: 30rem;
  padding:0rem 0rem 0rem 0rem;
  margin:0;
}

.button2 {
  font-family: Avenir-light;
	cursor:pointer;
	border:none;
	padding:0rem 0rem 0rem 0rem;
  margin:0rem 0rem 3rem 5rem;
	font-size:15px;
  width: 14rem;
  background-color: #f6e176;
  background-color: rgb(33, 33, 33);
  color: white;
  border: 2px solid rgb(33, 33, 33);
  border-radius: 1px;
}

.button2:hover {
  background-color: #f8eba8;
  border: 2px solid #f8eba8;
  color: black;
	-webkit-transition:ease-in-out;
	-moz-transition:background 0.3s ease-in-out;
	transition:background-color 0.3s ease-in-out;
}

.arrow-image{
size: 20;
}

@media(max-width:900px) {
  .about-section h4 {
    font-size: 35px;
    font-weight: 200;
    padding: 2rem 5rem 1rem 5rem;
    margin-bottom: 0;
    text-align: center;
  }
  .about-split{
    display:block;
    resize: none;
  }
  .about-section p {
    padding: 1rem 4rem 0rem 5rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 16px;
  }
  .about-image{
    padding: 1rem 5rem 3rem 5rem;
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    
  }
}
@media only screen and (max-width: 500px) {

  .about-section{
    display:block;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  .button2{
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .about-section h4 {
  	padding: 3rem 2rem 0.5rem 2rem;
    margin:0;
    font-size: 27px;
    font-weight: 100;
  }
  .about-section p {
    padding: 1rem 2rem 0rem 2rem;
    text-align: center;
    line-height: 25px;
    font-size: 14px;
    font-weight: 100;
  }

  .about-image{
    height: 18rem;
    width: 24rem;
    padding: 0rem 1rem 3rem 1rem;
    align-items: center;
  }
}

@media only screen and (max-width: 400px) {

    .about-section{
      display:block;
      text-align: center;
      margin: 0;
      padding: 0;
      width: inherit;
    }

    .about-split{
      display:block;
      text-align: center;
    }

    .about-section h4 {
    	padding: 3rem 2rem 0.5rem 2rem;
      margin:0;
      font-size: 22px;
      font-weight: 100;
    }
    .about-section p {
      padding: 1rem 2rem 0rem 2rem;
      text-align: center;
      line-height: 21px;
      font-size: 11px;
      font-weight: 100;
    }
    .about-image{
      height: 15rem;
      width: 18rem;
      padding: 0rem 1rem 3rem 1rem;
      align-items: center;
    }

  .button2 {
     font-size: 13px;
   }
  }


/*---------------------------SERVICES HOME PAGE---------------------------*/

.service {
  margin: 0;
  background-color: white;
}
.service-text{
	display: flex;
	padding: 1rem 0 1rem 0;
}

.service-text h4 {
	width: 30%;
  text-align: left;
	font-size: 38px;
	font-weight: 200;
	padding: 4rem 4rem 0rem 8rem;
	resize:none;
  margin: 0;
}

.service-text p {
  text-align: left;
	font-size: 16px;
	font-weight: 200;
	padding: 4rem 7rem 0rem 0rem;
  resize:none;
  margin: auto;
}

.container{
	padding: 0rem 5rem 1rem 5rem;
	resize:none;
	text-align: center;
	display:flex;
	justify-content: center;
	align-items: center;
  flex-wrap: wrap;
}

.container h4{
	resize:none;
	font-size: 20px;
	font-weight: 200;
	padding: 2rem 2rem 0.5rem 2rem;
	resize:none;
	margin: 0;
}

.container:hover p{
	line-height:26px;
	font-size: 15px;
	font-weight: 300;
	padding: 0rem 1rem 0rem 1rem;
	resize: none;
	text-align: left;
}
.pre:hover h4, .pre:hover p{
	visibility: visible;
}
.present:hover h4, .present:hover p{
	visibility: visible;
}
.post:hover h4, .post:hover p{
	visibility: visible;
}

.item img{
	width: 100%;
	margin:0;
	position:absolute;
	visibility: visible;
	display: flex;
	justify-content: center;
	align-items: center;
}

.item:hover img{
	position:none;
	visibility: hidden;
}

.item {
  text-align: center;
  border-radius: 0px;
  border: 2.3px solid rgba(0, 0, 0, 0.04);
  overflow: hidden;
  display: flex;
  resize: none;
  padding: 0;
  background: white;
  width: 310px;
  height: 200px;
  margin: 10px;
  flex-direction: column;
  justify-content: space-between;
}

.item:hover{
  background: #F0EFED;
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  -webkit-transition: all .5s ease 0;
  transition: all 0.5s ease 0s;
  -webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.10);
}

.service-icons{
	font-size: 30px;
	color: black;
	display:inline-flex;
	justify-content: left;
	align-items: left;
	padding: 2rem 0rem 0rem 3rem;
}

.service-button {
  font-family: Avenir-light;
	cursor:pointer;
	border:none;
  margin: 0rem 0px 6rem 67.5% ;
	padding:0.8rem 2rem 0.8rem 2rem;
	font-size:15px;
  width: 15rem;
  background-color: #f6e176;
  background-color: rgb(33, 33, 33);
  color: white;
  border: 2px solid rgb(33, 33, 33);
  border-radius: 1px;
}

.service-button:hover {
  background-color: #f8eba8;
  border: 2px solid #f8eba8;
  color: black;
	-webkit-transition:ease-in-out;
	-moz-transition:background 0.3s ease-in-out;
	transition:background-color 0.3s ease-in-out;
}

.container-button{
  font-family: Avenir-light;
  cursor:pointer;
	border:none;
  margin: 0rem 0px 0rem 0rem;
	padding:0rem 0rem 0rem 0rem;
	font-size:15px;
  width: 100%;
  height:40px;
  background-color: #f6e176;
  color: rgb(76, 75, 75);
  border-radius: 0px;
  align-content: flex-end;
}

.container-button:hover {
  background-color: #ffffff;
  color: rgb(76, 75, 75);
	-webkit-transition:ease-in-out;
	-moz-transition:background 0.3s ease-in-out;
	transition:background-color 0.3s ease-in-out;
}


.see-more-button {
  font-family: Avenir-light;
	border:none;
	padding:0.8rem 2rem 0.8rem 2rem;
	font-size:15px;
  width: 15rem;
  background-color: black;
  color: white;
  border-radius: 1px;
  position:absolute;
  right: 10rem;
  left: 10rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

.see-more-button:hover {
  color: white;
  background-color: rgb(76, 75, 75);
	-webkit-transition:background 0.3s ease-in-out;
	-moz-transition:background 0.3s ease-in-out;
	transition:background-color 0.3s ease-in-out;
}

@media only screen and (max-width: 980px) {

  .item p{
    font-size:12px;
    line-height:26px;
    font-weight: 300;
  }

  .item h4 {
    text-align: center;
  	font-size: calc(20px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 200;
  	padding: 1rem 0rem 1rem 0rem;
    
  }
  .service-text{
    display: flex;
    resize:none;
    flex-direction: column;
    overflow: hidden;
    width: fit-content;
  }

  .service-text p{
    padding: 1rem 4rem 0 4rem;

  }
  .service-text h4{
    padding: 4rem 0rem 0rem 4rem;

  }
}


@media only screen and (max-width: 800px) {
  .container{
    padding: 0rem 0rem 1rem 0rem;
    resize:none;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .item p{
    font-size:14px;
    line-height:26px;
    font-weight: 200;
  }

  .item h4 {
  	font-size: calc(20px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
  	font-weight: 200;
  	padding: 1rem 0rem 1rem 0rem;
  }
  .service-button{
      align-items: center;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 310px;    
  }
}

@media only screen and (max-width: 500px) {
  .service h4{
    padding-bottom: 1rem;
    font-size: 30px;
  }

.service-text h4 {
	width: 100%;
  text-align: center;
	font-size: 33px;
	font-weight: 200;
	padding: 4rem 0rem 0rem 0rem;
	resize:none;
  margin: 0;
}

.service-text p {
  text-align: center;
	font-size: 16px;
	font-weight: 200;
  width: 80%;
	padding: 2rem 1rem 0rem 1rem;
  resize:none;
  margin: auto;
}

.container{
	padding: 0rem 0rem 1rem 0rem;
	resize:none;
	text-align: center;
	display:flex;
	justify-content: center;
	align-items: center;
  flex-wrap: wrap;
}

.container h4{
	resize:none;
	font-size: 20px;
	font-weight: 200;
	padding: 2rem 2rem 0rem 2rem;
	resize:none;
	margin: 0;
}
  .item {
    text-align: center;
    border-radius: 0px;
    border: 2.3px solid rgba(0, 0, 0, 0.04);
    overflow: hidden;
    display: flex;
    resize: none;
    padding: 0;
    margin: 10px;
    justify-content: flex-end;
    align-items: stretch;
    flex-direction: column;
    justify-content: space-between;
  }
  

  .service-icons{
    font-size: 30px;
  }

  .item p{
    font-size:14px;
    line-height:26px;
    font-weight: 200;
  }
  .service {
  	background-color: white;
    align-content: center;
    resize:none;
  }
  .item h4 {
    text-align: center;
  	font-size: 20px;
  	font-weight: 200;
  	padding: 1rem 0rem 1rem 0rem;
    width: auto;
    height: auto;
    resize:none;
    margin: auto;
		color: #f6e176;
  }


.see-more-button {
  font-family: Avenir-light;
	border:none;
	padding:0.8rem 2rem 0.8rem 2rem;
	font-size:15px;
  width: 15rem;
  background-color: black;
  color: white;
  border-radius: 1px;
  position:absolute;
  right: 2rem;
  left: 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

}

/*----------------------------------------------------------------*/

/*--------------------------Mid section --------------------------*/

.Mid1 {
  background-image: url('./images/grey.jpg');
	height:15rem;
	width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	position: relative;
	background-repeat: no-repeat;
	justify-content: center;
	background-attachment: fixed;
	color: rgb(0, 0, 0);
	opacity: 0.7;
  background-attachment: scroll !important;

}

.Mid1 h4 {
	font-size: 30px;
  padding: 6rem 3rem 2rem 3rem;
  font-weight: 300;
  margin: 0;
	text-align: center;
}


@media only screen and (max-width: 500px) {
  .Mid1 {
    height:20rem;
    width: 100%;
  }
  .Mid1 h4 {
    font-size: 20px;
    padding: 8rem 3rem 0rem 3rem;
    font-weight: 300;
    margin: 0;
    text-align: center;
  }
}

a {/* this is link to service page titles*/
  text-decoration: none;
  color: black;
}

.service-link :hover {
  color: #f6e176;
  transform: scaleY(1.1);
  transition: 0.3s;
}

.service-link-img :hover {
  color: #f6e176;
  transform: scale(1.1);
  transition: 0.3s;
}

/*----------------------------------------------------------------*/

/*----------------------------Testimonials--------------------------*/


.testimonials {
	flex-direction: column;
	min-height: 60vh;
  background-color: white;
  color: white;
  margin:0;
  padding:0;
	display: flex;
}
.testimonials h4 {
	font-weight: 300;
	padding: 2rem 4rem 0rem 5rem;
  font-size: 44px;
  line-height: 40px;
	color: black;
}

.test-container  p {
  font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 2rem 0rem 3rem;
  font-size: 20px;
  line-height: 33px;
	color: black;
	justify-content: left;
	text-align: left;
}

.test-container h5 {
	font-weight: 300;
	padding: 0rem 4rem 0rem 3rem;
  font-size: 23px;
  line-height: 33px;
	color: black;
	justify-content: left;
	text-align: left;
}
.test-container{
	min-height: 65vh;
	margin-bottom: 4rem;
	display: flex;
}
.test-div{
	min-width: 150px;
  max-width: 410px;
	justify-content: left;
	text-align: left;
}

.test-underline{
	position: relative;
	border: none;
	height: 3px;
	width: 7rem;
	background: #DBEEFF;
	margin-bottom: 3rem;
	margin-left: 5rem;
}

.pic {
  display: flex;
  border: 4px solid white;
	padding-bottom: 1rem;
	justify-content: center;
	text-align: center;
}

.pic img {
  width: 10rem;
  height: 10rem;
}

.test-container {
  position:relative;
	justify-content: center;
	text-align: center;
}

.testimony-pic {
  display: inline-block;
  border: 4px solid white;
  border-radius: 50%;
  box-shadow: 0 0 4px 4px #f6e176;
  overflow: hidden;
  z-index: 1;
}
.testimonial-icons{
	font-size: 30px;
	color: black;
	display:inline-flex;
	justify-content: left;
	align-items: left;
	padding: 2rem 0rem 0rem 3rem;

}


@media only screen and (max-width: 980px) {


.test-container{
	width :100%;
	height :100%;
	justify-content: center;
	text-align: center;
}
.service-icons{
	font-size: 30px;
	padding: 1rem 0 0 0;
}

.service {
	background-color: white;
	color: black;
	align-content: center;
	resize:none;
}
}


@media only screen and (max-width: 800px) {


.test-container{
	width :100%;
	height :100%;
	justify-content: center;
	text-align: center;
	display :block;

}
.service-icons{
	font-size: 30px;
}


.service {
	background-color: white;
	color: black;
	align-content: center;
	resize:none;
}

}


/*----------------------------------------------------------------*/

/*---------------------------- Editorial Page ------------------------------*/

.editorial {
	display: flex;
	flex-direction: column;
	min-height: 70vh;
	background-color: white;
	color: black;
  padding: 5rem 0 60px 0 ;
  text-align: left;
}

.editorial h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 2rem 0rem 1rem 5rem;
  margin-bottom: 0;
}

.editorial p {
	font-weight: 100;
  font-size: 20px;
	padding: 0rem 0rem 2rem 5rem;
  line-height: 33px;
}

.editorial-split{
	margin: 0;
	padding: 3rem 0rem 0rem 0rem;
	resize:none;
	text-align: center;
}

.editorial-img{
  height: 300px;
  width: 430px;
  padding: 0rem 0rem 0rem 0.5rem;
  margin:0;
  resize: both;
}

.editorial-div{
	padding: 0rem 0rem 5rem 0rem;
	resize:none;
	text-align: center;
	display:flex;
	justify-content: center;
	align-items: center;
}

.editorial-div h4 {
	font-size: 30px;
	font-weight: 200;
	padding: 2rem 0rem 1rem 0rem;
  margin-bottom: 0;
}

.underline{
  position: relative;
  border: none;
  height: 2px;
  width: 7rem;
  background: black;
  margin-bottom: 50px;
}

@media(max-width:898px) {


  .project p {
    padding: 0rem 3rem 1rem 5rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 15px;
    width:inherit;
  }
}
@media only screen and (max-width: 500px) {

	.project-split .project-img{
    height: 280px;
    width: 300px;
    margin-right:0;
  }
  .project h4 {
    	padding: 3rem 2rem 0.5rem 2rem;
      margin:0;
      font-size: 27px;
      font-weight: 200;
      text-align: center;

  }
  .project p {
      padding: 1rem 2rem 0rem 2rem;
      font-weight: 100;
      text-align: center;
      line-height: 25px;
      font-size: 14px;
      width:inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
}


/*----------------------------------------------------------------*/

/*----------------------------project------------------------------*/

.project {
	display: flex;
	flex-direction: column;
	min-height: 70vh;
	background-color: white;
	color: black;
  padding-bottom: 60px;
  text-align: left;
}

.project h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 2rem 0rem 1rem 5rem;
  margin-bottom: 0;
}

.project p {
	font-weight: 100;
  font-size: 20px;
	padding: 0rem 0rem 2rem 5rem;
  line-height: 33px;
}

.project-split{
	margin: 0;
	padding: 3rem 0rem 0rem 0rem;
	resize:none;
	text-align: center;
}

.project-img{
  height: 300px;
  width: 430px;
  padding: 0rem 0rem 0rem 0.5rem;
  margin:0;
  resize: both;
}

.project-div{
	padding: 0rem 0rem 5rem 0rem;
	resize:none;
	text-align: center;
	display:flex;
	justify-content: center;
	align-items: center;
}

.project-div h4 {
	font-size: 30px;
	font-weight: 200;
	padding: 2rem 0rem 1rem 0rem;
  margin-bottom: 0;
}


@media(max-width:898px) {


  .project p {
    padding: 0rem 3rem 1rem 5rem;
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 15px;
    width:inherit;
  }
}
@media only screen and (max-width: 500px) {

	.project-split .project-img{
    height: 280px;
    width: 300px;
    margin-right:0;
  }
  .project h4 {
    	padding: 3rem 2rem 0.5rem 2rem;
      margin:0;
      font-size: 27px;
      font-weight: 200;
      text-align: center;

  }
  .project p {
      padding: 1rem 2rem 0rem 2rem;
      font-weight: 100;
      text-align: center;
      line-height: 25px;
      font-size: 14px;
      width:inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
}

/*----------------------------------------------------------------*/

/*-----------------------------Project Page----------------------------*/

.projectpage-item {
  text-align: center;
  border-radius: 0px;
  border:2.3px solid rgba(0, 0, 0, 0.04);
  overflow: hidden;
	display:flex;
	resize: none;
	padding: 0;
	display :inline-block;
	background: white;
	width: 310px;
	height: 360px;
	margin :10px;

}

.projectpage-item:hover{
  background: #f6e176;
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  -webkit-transition: all .5s ease 0;
  transition: all 0.5s ease 0s;
  -webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.10);
  border-bottom: 5px solid #f6e176;
}

.projectpageitem img{
	width: 100%;
	margin:0;
	position:absolute;
	visibility: visible;
	display: flex;
	justify-content: center;
	align-items: center;
}

.projectpage-item:hover img{
	position:none;
	visibility: hidden;
}

.projectpage-item p{
  line-height:2px;
  font-size: 15px;
  font-weight: 300;
  padding: 4rem 0rem 1rem 0rem;
  resize: none;
}
.projectpage-item h4{
  text-align: center;
  font-size: calc(10px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 100;
  padding: 0.5rem 0rem 0.5rem 0rem;
  resize:none;
  margin-top: 0;
  margin-bottom: 0;
  color: #f6e176;

}



/*----------------------------------------------------------------*/

/*-----------------------------Portfolio Page----------------------------*/

.portfolio {
	display: flex;
	flex-direction: column;
	min-height: 70vh;
	background-color: white;
	color: black;
  padding: 40px 0 60px 0;
  text-align: left;
}

.portfolio h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 2rem 0rem 1rem 5rem;
  margin-bottom: 0;
}

.portfolio p {
	font-weight: 100;
	font-size: calc(20px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	padding: 0rem 0rem 2rem 5rem;
  line-height: 33px;
}

.portfolio-div{
	padding: 0rem 0rem 5rem 0rem;
	resize:none;
	text-align: center;
	display:flex;
	justify-content: center;
  align-items: flex-start;
}

.portfolio-item {
  text-align: center;
  border-radius: 0px;
  overflow: hidden;
	display:flex;
	resize: none;
	padding: 0;
	display :inline-block;
	background: white;
	width: 400px;
	height: 420px;
	margin :10px;
}

.portfolio-item:hover{
  background: #F0EFED;
  box-shadow: 0 0 11px rgba(33,33,33,.2);
  -webkit-transition: all .5s ease 0;
  transition: all 0.5s ease 0s;
  -webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.10);
  border-bottom: 5px solid #f6e176;
}

.portfolio-container {
  width: 100%;
}
.portfolio-container p{
  padding: 0rem 0rem 0rem 4rem;
  text-align: left;
  width: 350px;
  font-weight: 100;
	font-size: calc(10px + (30 - 20) * ((100vw - 300px) / (1600 - 300)));

}
.portfolio-container h4{
  text-align: center;
  font-size: 24px;
  font-weight: 100;
  padding: 0.5rem 0rem 0.5rem 0rem;
}

@media(max-width:1000px) {

  .portfolio-div{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .portfolio {
    margin: auto;
    width: 100%;
    padding: 50px 0 60px 0;
  }

  .portfolio p {
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 15px;
    justify-content: left;
    align-items: left;
    display: block;
    margin: auto;
    width: 60%;
    padding: 10px;
  }

  .portfolio-container {
    width: 90%;
    text-align: center;
    display: block;
    align-items: center;
  }

  .portfolio-container h4{
    text-align: center;
    font-size: 26px;
    font-weight: 100;
    padding: 0.5rem 0rem 0.5rem 0rem;
  }

}
@media only screen and (max-width: 650px) {

  .portfolio {
    margin: auto;
    width: 100%;
    padding: 70px 0 60px 0;
  }
  .portfolio p {
    text-align: left;
    font-weight: 100;
    line-height: 25px;
    font-size: 15px;
    justify-content: left;
    align-items: left;
    display: block;
    margin: auto;
    width: 80%;
    padding: 10px;
  }
  .portfolio-item img{
    text-align: center;
    display:flex; 
    width: 370px;
    height: 430px;
    margin: auto;

  }
  .portfolio-container h4{
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: 100;
    padding: 0.5rem 0rem 0.5rem 0rem;
  }

}


/*----------------------------------------------------------------*/

/*-----------------------------Process cycle----------------------------*/

.process {
	display: flex;
	flex-direction: column;
	background-color: #DBEEFF;
	color: black;
  text-align: left;

}

.process h4 {
	font-size: calc(30px + (60 - 30) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 200;
	padding: 0rem 0rem 2rem 5rem;
  margin-bottom: 0;
}

.process p {
	font-weight: 100;
  font-size: 20px;
	padding: 0rem 0rem 0rem 5rem;
  line-height: 20px;
}

.process-img{
	width: 50%;
   position: absolute;
   right: 0px;
   height: 100%;
}

.process-split{
	display:flex;
}

@media only screen and (max-width: 898px) {
	.process-split{
		display: block;
		margin: auto;
		width: 100%;

	}
	.process-img{
		width: 100%;
	  position:relative;
	  height: 100%;
	}
}

/*----------------------------------------------------------------*/

/*-----------------------------CONTACT----------------------------*/



.contact h4 {
	font-size: 38px;
	font-weight: 600;
	padding: 0rem 3rem 0rem 3rem;
	text-align: left;
}

.contact p {
  font-size: calc(10px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
	font-weight: 100;
	padding: 0rem 0rem 0rem 0rem;
}

.contact{
  flex-direction: column;
  min-height: 60vh;
  color: black;
  align-content: flex-start;
  padding: 2rem 2rem 0rem 5rem;
	background-image: url('./images/grey.jpg');
	height:80%;
	width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	position: relative;
	background-repeat: no-repeat;
	background-attachment: fixed;
}
.contact-split{
  display:flex;
  resize: none;
}
.contact-split-left{
  padding: 2rem 1rem 6rem 1rem;
  display: inline;
  width:50%;
}
.contact-split-left h3{
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 0;
}
.contact-split-left p{
	font-size:15px;
}

.contact-msg{
  padding: 0;
  margin:0;
}

.input-group2 .bottom{
  line-height: 10px;
}
.input-row2{
  color: black;
  display: flex;
  font-size: 15px;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea, #contact button[type="submit"] { 
  font:400 12px/16px "Open Sans", Helvetica, Arial, sans-serif; }

#contact {
  min-height: 60vh;
  text-align: left;
  width: 100%;
	padding:25px;
  padding-top: 5rem;
  resize: none;
}

#contact h3 {
	display: block;
	font-size: 30px;
	font-weight: 400;
}
#contact p {
	margin:5px 0 15px;
	display:block;
	font-size:13px;
  color: black;
}

fieldset {
	border: medium none !important;
	margin: 0 0 10px;
	min-width: 100%;
	padding: 0;
  resize: none;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea {
	width:70%;
	border:1px solid #CCC;
	background-color: #F9F9F9;
	margin:0 0 5px;
	padding:10px;
  resize: none;
}

#contact input[type="text"]:hover, #contact input[type="email"]:hover, #contact input[type="tel"]:hover, #contact input[type="url"]:hover, #contact textarea:hover {
	-webkit-transition:border-color 0.3s ease-in-out;
	-moz-transition:border-color 0.3s ease-in-out;
	transition:border-color 0.3s ease-in-out;
	border:1px solid #AAA;
}

#contact textarea {
	height:100px;
  resize:none;
}

#contact button[type="submit"] {
	cursor:pointer;
  width:73%;
	border:none;
	background:#363636;
	color:#FFF;
	font-size:15px;
}

#contact button[type="submit"]:hover {
	background:#f6e176;
  color: black;
	-webkit-transition:background 0.3s ease-in-out;
	-moz-transition:background 0.3s ease-in-out;
	transition:background-color 0.3s ease-in-out;
}

#contact input:focus, #contact textarea:focus {
	outline:0;
	border:1px solid #999;
}
::-webkit-input-placeholder {
 color:#888;
}
:-moz-placeholder {
 color:#888;
}
::-moz-placeholder {
 color:#888;
}
:-ms-input-placeholder {
 color:#888;
}

.div-map{
  display: flex;
  padding: 0rem 4rem 4rem 0rem;
}
.map{
  width:350px;
  height:250px;
}

.contact-div{
	flex-direction: column;
	min-height: 80vh;
	align-content: flex-start;
	padding: 10rem 2rem 0rem 5rem;
  background-image: url('./images/grey.jpg');
	height:80%;
	width: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	position: relative;
	background-repeat: no-repeat;
	background-attachment: fixed;
	color: rgb(0, 0, 0);
}

.contact-div h3{
  color: black;
}
@media only screen and (max-width: 898px) {
  .contact-split{
    display:block;
    resize: none;
}
  #contact {
    min-height: 60vh;
    text-align: left;
    width: 100%;
    padding: 2rem 0rem 0rem 1rem;
  }
  .contact-split-left{
    padding: 0rem 7rem 0rem 0rem;
  }
  .contact-split-left h3{
    padding: 0rem 0rem 0rem 1rem;
    font-size: 30px;
    font-weight: 400;
  }
  .contact-split-left p{
    font-size:15px;
    padding: 0rem 0rem 0rem 1rem;
  }
  .map{
    width:350px;
    height:250px;
    margin: 1rem 3rem 0rem 1rem;
  }

}
@media only screen and (max-width: 898px) {
	.contact{
	  min-height: 60vh;
	  padding: 2rem 0 0rem 2rem;

	}
  .contact-div{
	  min-height: 60vh;
	  padding: 8rem 0 0rem 2rem;

	}
}

@media only screen and (max-width: 500px) {
  .map{
    width:300px;
    height:250px;
  }
}

/*------------------------------FOOTER----------------------------*/

.footer {
	text-align: center;
	background-color: white;
	color: black;
	line-height: 70px;
	height: 70px;
	text-align: center;
	box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.5);

}

@media only screen and (max-width: 898px) {

  .footer {
    text-align: center;
    background-color: white;
    color: black;
    font-size: 13px;
    line-height: 70px;
    height: 70px;
    text-align: center;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.5);
  
  }
  
}


/*----------------------------------------------------------------*/

/*----------------------------SCROLL------------------------------*/

.MuiFab-secondary {
	background-color: blue !important;
	color: white !important;
}

/*----------------------------------------------------------------*/
/*----------------------------ABOUT PAGE CSS------------------------------*/

.aboutpage-CN {
	display: flex;
	flex-direction: column;
	background-color: white;
	color: black;
  resize: none;
  padding-top: 80px;
}
.header-image{
  text-align: left;
	display: flex;
	flex-direction: column;
	color: black;
  overflow:scroll;
}
.abt-back{
  padding: 4rem 0rem 6rem 0rem;
  text-align: center;
}

.aboutpage-CN h4 {
	font-size: 40px;
	font-weight: 300;
	padding: 0rem 3rem 0rem 3rem;
	text-align: center;

}

.aboutpage-CN p {
	font-size: 20px;
	font-weight: 100;
	padding: 0rem 7rem 0rem 7rem;
  line-height: 33px;
}

.aboutpage-split{
  display:flex;
  font-size: 30px;
	font-weight: 600;
	padding: 1rem 10rem 5rem 10rem;
  text-align: center;
  justify-content: center;
}
.aboutpage-text{
  padding: 0rem 0rem 0rem 1.5rem;
  text-align: left;
  margin-top: 0;
}

.aboutpage-text h4 {
	font-size: 30px;
  height: 10px;
  text-align: left;
  margin-top: 0;
  padding: 0rem 0rem 0rem 2rem;
}

.aboutpage-text p {
  font-size: 17px;
  width: auto;
  resize: none;
  padding: 0rem 0rem 0rem 2rem;
  margin-top: 0;
  text-align: left;
}
.abt-back2{
  background-color: #c7baa3;
  color: white;
  padding: 0rem 2rem 5rem 2rem;
  text-align: center;
}
.abt-back2 h4{
  padding: 3rem 0rem 0.7rem 0rem;
  margin-bottom: 0;

}
.underline{
  position: relative;
  border: none;
  height: 2px;
  width: 7rem;
  background: white;
  margin-bottom: 50px;
}
.abt-p{
  display: flex;
  resize: none;
}
.abt-p h5{
  padding: 1rem 3rem 0rem 3rem;
  font-size: 20px;
  text-align: center;
}

.abt-p p{
  padding: 1rem 3rem 1rem 3rem;
  font-size: 20px;
  text-align: center;
}
#about-img{
  width: 30rem;
  height: 26rem;
  padding:0rem 0rem 0rem 0rem;
  margin:0;
}


@media only screen and (max-width: 900px) {

  .aboutpage-split{
    display:block;
    align-items: center;
    display:flex;
    font-size: 30px;
    padding: 1rem 2.5rem 5rem 2.5rem;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}

  #about-img{
    resize: none;
    margin-left: auto;
    margin-right: auto;
    display: block;
    align-items: center;
    width: 25rem;
  }

  .abt-back p {
  	padding: 1rem 3rem 0rem 3rem;
    font-size: 18px;
  }
  .aboutpage-CN h4 {
    font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
    padding: 1rem 0rem 2rem 0rem;
  	font-weight: 300;
    margin-bottom: 0;
  }
  .abt-back2 p{
    padding: 1rem 0rem 0rem 0rem;
    font-size: 18px;
  }
  .aboutpage-text p{
    padding: 1rem 0rem 0rem 0rem;
    font-size: 18px;
    }
}

@media only screen and (max-width: 500px) {

  .aboutpage-split{
    display:block;
    align-items: center;
    display:flex;
    font-size: 30px;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}
    #about-img{
      resize: none;
      margin-left: auto;
      margin-right: auto;
      display: block;
      align-items: center;
      width: 80%;
      height: 80%;
    }
    
    .aboutpage-CN h4 {
      font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
      padding: 1rem 0rem 3rem 0rem;
      font-weight: 300;
      margin-bottom: 0;
    }
    .aboutpage-text{
      padding: 0rem 0rem 0rem 0rem;
      text-align: left;
      margin-top: 0;
    }

    .aboutpage-text p {
      font-size: 14px;
      width: 305px;
      resize: none;
      padding: 0rem 0rem 0rem 0rem;
      margin-top: 0;
      text-align: left;
    }

    .aboutpage-CN p{
      font-size: 14px;
    }
}

@media only screen and (max-width: 300px) {

      .aboutpage-CN p{
        font-size: 11px;
        line-height: 20px;
        padding: 1rem 0rem 0rem 0rem;
      }
      .aboutpage-CN h4{
        font-size: 26px;
      }

      .aboutpage-split{
        display:block;
        justify-content: center;
      }


    .aboutpage-split img{
        height: 15rem;
        width: 15rem;
        justify-content: center;
        padding: 0rem 0rem 2rem 0rem;
        margin-left: auto;
        margin-right: auto;
      }

      .abt-back p {
      	padding: 1rem 2rem 0rem 2rem;
        font-size: 11px;
        margin: 0;
      }
      .abt-p h5{
        justify-content: center;
        padding: 0;
      }

      .aboutpage-text h4{
        text-align: center;
        margin: 0;
      }
      .aboutpage-text p{
        font-size: 11px;
        line-height: 20px;
        padding: 1rem 0rem 0rem 0rem;
        width: 210px;
        margin-top: 0;
        text-align: center;
      }
    }

/*----------------------------CLIENT PAGE CSS------------------------------*/

.Services {
	display: flex;
	flex-direction: column;
  align-content: flex-start;
  text-align: center;
  padding: 7rem 5rem 3rem 5rem;
}
.Services h4 {
	font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
  padding: 1rem 0rem 1rem 0rem;
	font-weight: 300;
  background-color: white;
  color:black;
  margin-bottom: 0;
}

.Services p {
	font-size: 16.5px;
	font-weight: 80;
  font-style:normal;
  padding: 1rem 5rem 1rem 5rem;
}
.service-split-img{
  height: 350px;
  width: 300px;
  padding: 1rem 2rem 0rem 2rem;
}
.service-page-img{
  width: 200px;
  padding: 1rem 2rem 0rem 2rem;
}

.service-split{
  display:flex;
  color: black;
  resize: none;
  padding: 0rem 0rem 0rem 0rem;
  margin-right: 0;
}

.section3-CN {
	display: flex;
	flex-direction: column;
	background-color: white;
	color: black;
  align-content: flex-start;
  padding: 8em 0rem 8rem 0rem;
}

.section3-CN h4 {
  font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 300;
  color: black;
  padding: 0rem 0rem 0rem 3rem;
  text-align: left;
  margin: 0;
}
.section3-CN p {
  font-size: 18px;
	font-weight: 100;
	padding: 0rem 3rem 0rem 3rem;
}
.sectionpage-div{
	display: flex;
	flex-direction: column;
	background-color: white;
	color: black;
  align-content: flex-start;
  padding: 0rem 0rem 2rem 0rem;
}

.sectionpage-div h4 {
  font-size: 30px;
	font-weight: 300;
  color: black;
  padding: 0.5rem 0rem 0rem 2rem;
  text-align: left;
  margin: 0;
}
.sectionpage-div p {
  text-align: left;
  font-size: 16.5px;
	font-weight: 100;
	padding: 0rem 3rem 0rem 2rem;
}

@media only screen and (max-width: 828px) {
  .Services{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: center;
    padding: 7rem 2rem 0rem 2rem;
  }
  .Services h4 {
  	font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
    padding: 1rem 0rem 1rem 0rem;
  	font-weight: 300;
    background-color: white;
    color:black;
    margin-bottom: 0;
  }

  .Services p {
  	font-size: calc(13px + (60 - 40) * ((100vw - 300px) / (2000 - 300)));
  	font-weight: 80;
    padding: 1rem 0rem 1rem 0rem;
  }
  .service-split{
    display:block;
  }
  .service-split h4{
    text-align: center;
    padding: 3rem 2rem 0rem 2rem;
  }
  .service-split p{
    text-align: center;
    padding: 0rem 2rem 0rem 2rem;
  }
  .section3-CN {
    padding: 7rem 0rem 8rem 0rem;
  }

}
@media only screen and (max-width: 500px) {
  .Services{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: center;
    padding: 7rem 1rem 1rem 1rem;
  }
  .Services h4 {
  	font-size: calc(30px + (60 - 40) * ((100vw - 300px) / (1600 - 300)));
    padding: 1rem 0rem 1rem 0rem;
  	font-weight: 300;
    background-color: white;
    color:black;
    margin-bottom: 0;
  }

  .Services p {
  	font-size: 14px;
  	font-weight: 80px;
    font-style: normal;
    padding: 1rem 0rem 1rem 0rem;
  }
  .service-split{
    display:block;
  }
  .service-split h4{
    text-align: center;
    padding: 2rem 2rem 0rem 2rem;
  }
  .service-split p{
    text-align: center;
    font-size: 14px;
    padding: 0rem 1rem 2rem 1rem;
  }

}

@media only screen and (max-width: 300px) {
  .Services{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    text-align: center;
    padding: 7rem 2rem 0rem 2rem;
  }
  .Services h4 {
  	font-size: 26px;
    padding: 1rem 0rem 1rem 0rem;
  	font-weight: 300;
    background-color: white;
    color:black;
    margin-bottom: 0;
  }

  .Services p {
  	font-size: 11px;
  	font-weight: 20px;
    font-style: italic;
    line-height: 20px;
    padding: 1rem 0rem 0rem 0rem;
  }
  .service-split{
    display:block;
    padding: 0rem 0rem 0rem 0rem;
    margin: 0;

  }
  .service-split h4{
    text-align: center;
    padding: 3rem 2rem 0rem 2rem;
  }
  .service-split p{
    text-align: center;
    font-size: 11px;
    line-height: 20px;
    padding: 0rem 2rem 0rem 2rem;
  }

}

/*---------------------- Scroll behavior ---------------------*/


.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.reveal {
  position: relative;
  opacity: 0;
  animation-iteration-count: 1;
}

.reveal.active {
  opacity: 1;
  animation-iteration-count: 1;
}
.active.fade-bottom {
  animation: fade-bottom 0.8s  ease-in;
}
.active.fade-left {
  animation: fade-left 0.6s ease-in;
}
.active.fade-right {
  animation: fade-right 0.6s ease-in;
}
@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;

  }
  100% {
    transform: translateY(0);
    opacity: 1;

  }
}
@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/*----------------------------*/

.thankyou{
  	text-align: center;
  	display: flex;
  	flex-direction: column;
    background-image: url('./images/grey.jpg');
    padding-top: 30px;
    color: black;
    background-size: 100rem;
    margin-top: 80px;

}

.thankyou h4{
  padding: 10rem;
  text-align: center;
  font-size: calc(40px + (90 - 40) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 500;
}

@media only screen and (max-width: 500px) {
  .thankyou{
    	text-align: center;
    	display: flex;
    	flex-direction: column;
      padding-top: 80px;
      color: black;
      background-size: 100rem;
      margin-top: 80px;
      height: 40rem;
  }
  .thankyou h4{
    padding: 5rem;
    text-align: center;
    font-size: calc(40px + (90 - 40) * ((100vw - 300px) / (1600 - 300)));
    font-weight: 500;
  }
}

/*---------------------- Carousel ---------------------*/
.aws-btn {

  --slider-height-percentage: 60%;
  --slider-transition-duration: 525ms;
  --organic-arrow-thickness: 4px;
  --organic-arrow-border-radius: 0px;
  --organic-arrow-height: 40px;
  --organic-arrow-color: #6a6a6a;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #6a6a6a;
  --control-bullet-active-color: #6a6a6a;
  --loader-bar-color: #851515;
  --loader-bar-height: 6px;
  }

.carousel-div{
  padding: 0rem 2rem 0 2rem ;
}

.carousel-div h2{
  text-align: center;
  font-weight: 100;
  line-height: 40px;
  font-size: 25px;
  margin: auto;
  width: 90%;
  padding: 12rem 1rem 6rem 1rem ;
}

.carousel{
  height: 20rem;
}

@media only screen and (max-width: 900px) {
  .carousel-div{
    padding: 4rem 2rem 0 2rem ;
  }
  .carousel-div h2{
    text-align: center;
    font-weight: 100;
    line-height: 40px;
    font-size: 20px;
    margin: auto;
    width: 100%;
    padding: 1rem 0rem 4rem 0rem ;
  }
}
@media only screen and (max-width: 500px) {
  .carousel-div{
    padding: 4rem 2rem 0 2rem ;
  }
  .carousel-div h2{
    text-align: center;
    font-weight: 100;
    line-height: 38px;
    font-size: 18px;
    margin: auto;
    width: 100%;
    padding: 1rem 0rem 4rem 0rem ;
  }
}

.legend {
  transition: all .5s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  opacity: 0.25;
  transition: opacity .35s ease-in-out;
  background: #f6e176; 
 }

 
 .yourSelector {
  :global( .slide .legend ) {
          background: blue;
        }
      }




#carousel {
  position: relative;
  height: 200px;
  width: 810px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.arrow {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #f6e176;
  text-align: center;
  font-size: 25px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  line-height: 30px;
  margin-top: 85px;
  z-index: 1000;
}

.arrow-right {
  left: 780px;
}

.carousel-item {
  text-align: center;
  color: white;
  font-size: 40px;
  position: absolute;
  transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s, background-color 1s;
}

.level-2 {
  height: 160px;
  width: 120px;
  line-height: 150px;
  background-color: #228291;
  left: 650px;
  margin-top: 25px;

}

.level-1 {
  height: 180px;
  width: 130px;
  line-height: 180px;
  background-color: #6796E5;
  left: 500px;
  margin-top: 10px;
}

.level0 {
  height: 200px;
  width: 150px;
  line-height: 200px;
  background-color: #4EC9E1;
  left: 330px;
}

.level1 {
  height: 180px;
  width: 130px;
  line-height: 180px;
  background-color: #6796E5;
  margin-top: 10px;
  left: 180px;
}
.level1 {
  height: 180px;
  width: 130px;
  line-height: 180px;
  background-color: #6796E5;
  margin-top: 10px;
  left: 180px;
}

.level2 {
  height: 160px;
  width: 120px;
  line-height: 150px;
  background-color: #228291;
  margin-top: 25px;
  left: 50px;
}


.level-2 img{
  height: 160px;
  width: 120px;
  line-height: 150px;
  background-color: #228291;
  left: 650px;
  margin-top: 0px;
}

.level2 img{
  height: 160px;
  width: 120px;
  line-height: 150px;
  background-color: #228291;
  margin-top: 0px;
  left: 50px;
}

.level-1 img{
  height: 180px;
  width: 130px;
  line-height: 180px;
  background-color: #6796E5;
  left: 500px;
  margin-top: 0px;
}

.level0 img{
  height: 200px;
  width: 150px;
  line-height: 200px;
  background-color: #4EC9E1;
  left: 330px;
}

.level1 img{
  height: 180px;
  width: 130px;
  line-height: 180px;
  background-color: #6796E5;
  margin-top: 0px;
  left: 180px;
}
.level1 img{
  height: 180px;
  width: 130px;
  line-height: 180px;
  background-color: #6796E5;
  margin-top: 0px;
  left: 180px;
}



.left-enter {
  opacity: 0;
  left: 50px - 110px;
  height: 150px - 30;
  width: 110px - 20;
  line-height: 150px - 30;
  margin-top: 40px;
}

.left-enter.left-enter-active {
  opacity: 1;
  left: 50px;
  height: 150px;
  width: 110px;
  line-height: 150px;
  margin-top: 25px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.left-leave {
  opacity: 1;
  left: 650px;
  height: 150px;
  width: 110px;
  line-height: 150px;
  margin-top: 25px;
}

.left-leave.left-leave-active {
  left: 650px + 110px + 20;
  opacity: 0;
  height: 150px - 30;
  line-height: 120px;
  margin-top: 40px;
  width: 110px - 20;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.right-enter {
  opacity: 0;
  left: 650px + 110px;
  height: 150px - 30;
  width: 110px - 20;
  line-height: 150px - 30;
  margin-top: 40px;
}

.right-enter.right-enter-active {
  left: 650px;
  opacity: 1;
  height: 150px;
  margin-top: 25px;
  line-height: 150px;
  width: 110px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.right-leave {
  left: 50px;
  height: 150px;
  opacity: 1;
  margin-top: 25px;
  line-height: 150px;
  width: 110px;
}

.right-leave.right-leave-active {
  left: 50px - 110px;
  opacity: 0;
  height: 150px - 30;
  width: 110px - 20;
  line-height: 150px - 30;
  margin-top: 40px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.noselect {
  -webkit-user-select: none; 
   -khtml-user-select: none; 
     -moz-user-select: none; 
      -ms-user-select: none;  
          user-select: none;
}



.card {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 20rem;
  height: 20rem;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.carousel-img{
  width: 18rem;
  height: 18rem;
}
button {
  background-color: white;
  border: transparent;
  padding: 7px;
  height: 50px;
  width: 50px;
}
.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.wrapper {
  display: flex;
  margin: 20px;
}
